import { Suspense, lazy, useContext } from 'react';
import { WorkoutProvider, WorkoutContext } from '../../contexts/Workout';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import blogs from '../../content/blogs/blogs';
import projects from '../../content/projects/projects';
import copy from '../../content/copy/copy';
import ChatBot from '../ChatBot/ChatBot';
import NewProject from '../NewProject/NewProject';

// components
const Page = lazy(() => import('../Page/Page'));
const NotFoundPage = lazy(() => import('../NotFoundPage/NotFoundPage'));
const PayPal = lazy(() => import('../PayPal/PayPal'));

// blogs
const ItemList = lazy(() => import('../ItemList/ItemList'));
const Blog = lazy(() => import('../Blog/Blog'));

// projects
const InfiniteGifs = lazy(() => import('../InfiniteGifs/InfiniteGifs'));
const AviationWeather = lazy(
    () => import('../AviationWeather/AviationWeather'),
);
const YT = lazy(() => import('../YT/YT'));
const SpanishFlashcards = lazy(
    () => import('../SpanishFlashcards/SpanishFlashcards'),
);
const Timer = lazy(() => import('../Timer/Timer'));
const WorkoutTracker = lazy(() => import('../WorkoutTracker/WorkoutTracker'));

function AppRouter() {
    const { state, dispatch } = useContext(WorkoutContext);

    return (
        <WorkoutProvider value={{ state, dispatch }}>
            <BrowserRouter>
                <Suspense fallback={<div></div>}>
                    <Routes>
                        {/* HOME */}
                        <Route path={'/'} element={<Navigate to="/home" />} />
                        <Route
                            path={'/about'}
                            element={<Navigate to="/home" />}
                        />
                        <Route
                            path={'/home'}
                            element={
                                <Page className="Page">
                                    <>
                                        <h1>{copy.App.name}</h1>
                                        <br />
                                        <br />
                                        <p>
                                            <a
                                                href={`mailto:${copy.NavIcons.email}`}
                                            >
                                                {copy.App.cta}
                                            </a>
                                        </p>
                                    </>
                                </Page>
                            }
                        />

                        {/* BLOGS */}
                        <Route
                            path={'/blog'}
                            element={
                                <Page className="Page" isAlignedLeft={true}>
                                    <>
                                        <h1>{copy.Nav.blog}</h1>
                                        <ItemList
                                            className="BlogList"
                                            data={blogs}
                                            mode={'blog'}
                                        ></ItemList>
                                        <br />
                                        <PayPal className="PayPal" />
                                    </>
                                </Page>
                            }
                        />
                        <Route
                            path={'/blog/:path'}
                            element={<Blog className="Blog" />}
                        />

                        {/* PROJECTS LIST */}
                        <Route
                            path={'/projects'}
                            element={
                                <Page className="Page" isAlignedLeft={true}>
                                    <>
                                        <h1>{copy.Nav.projects}</h1>
                                        <ItemList
                                            className="ProjectList"
                                            data={projects}
                                            mode={'project'}
                                        ></ItemList>
                                        <br />
                                        <PayPal className="PayPal" />
                                    </>
                                </Page>
                            }
                        />
                        {/* PROJECTS ROUTES */}
                        <Route
                            path={'/projects/gifs'}
                            element={
                                <InfiniteGifs className="InfiniteGifs"></InfiniteGifs>
                            }
                        />
                        <Route
                            path={'/projects/aviation'}
                            element={
                                <AviationWeather className="AviationWeather"></AviationWeather>
                            }
                        />
                        <Route
                            path={'/youtube'}
                            element={<Navigate to="/projects/yt" />}
                        />
                        <Route
                            path={'/projects/youtube'}
                            element={<Navigate to="/projects/yt" />}
                        />
                        <Route
                            path={'/projects/yt'}
                            element={<YT className="YT"></YT>}
                        />
                        <Route
                            path={'/projects/flashcards'}
                            element={
                                <SpanishFlashcards className="SpanishFlashcards"></SpanishFlashcards>
                            }
                        />
                        <Route
                            path={'/projects/workout'}
                            element={
                                <WorkoutTracker className="Workout"></WorkoutTracker>
                            }
                        />
                        <Route
                            path={'/projects/timer'}
                            element={<Timer className="Timer"></Timer>}
                        />
                        <Route
                            path={'/projects/chat'}
                            element={<ChatBot className="ChatBot"></ChatBot>}
                        />

                        {/* NEW PROJECT / INTERVIEW SCRATCH SPACE */}
                        <Route
                            path={'/projects/new'}
                            element={
                                <NewProject className="NewProject"></NewProject>
                            }
                        />

                        {/* 404 NOT FOUND */}
                        <Route
                            path={'*'}
                            element={
                                <NotFoundPage className="NotFoundPage"></NotFoundPage>
                            }
                        />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </WorkoutProvider>
    );
}

export default AppRouter;

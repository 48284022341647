import { Exercise, Workout, WorkoutMetadata } from '../../types';

const defaultMetadata: WorkoutMetadata = {
    isDone: false,
    date: new Date(),
};

const defaultExerciseProperties = {
    numSetsComplete: 0,
};

const defaultStretch: Exercise = {
    humanReadableName: '🧘‍♂️ stretch',
    numSets: 1,
    ...defaultExerciseProperties,
};

export const defaultWorkout: Workout = {
    exercises: {
        stretch: defaultStretch,
        squat: {
            humanReadableName: '🏋️‍♂️ squat',
            numSetsComplete: 0,
            numSets: 3,
            reps: 10,
        },
        pushup: {
            humanReadableName: '💪 pushup',
            numSetsComplete: 0,
            numSets: 10,
            reps: 10,
        },
        situp: {
            humanReadableName: '💪 situp',
            numSetsComplete: 0,
            numSets: 16,
            reps: 10,
        },
        bicepCurl: {
            humanReadableName: '💪 bicep curl',
            numSetsComplete: 0,
            numSets: 3,
            reps: 6,
            weightLbs: 30,
        },
        shoulderPress: {
            humanReadableName: '💪 shoulder press',
            numSetsComplete: 0,
            numSets: 3,
            reps: 8,
            weightLbs: 35,
        },
        chessPress: {
            humanReadableName: '💪 chest press',
            numSetsComplete: 0,
            numSets: 3,
            reps: 8,
            weightLbs: 120,
        },
    },
    metadata: defaultMetadata,
};

export const gymWorkout: Workout = {
    exercises: {
        stretch: defaultStretch,
        swim: {
            numSetsComplete: 0,
            numSets: 1,
            humanReadableName: '🏊‍♂️ swim',
            timeMins: 10,
        },
        boxing: {
            humanReadableName: '🥊 boxing rounds',
            numSetsComplete: 0,
            numSets: 3,
            timeMins: 1,
        },
        pushup: {
            humanReadableName: '💪 pushup',
            numSetsComplete: 0,
            numSets: 6,
            reps: 10,
        },
        situp: {
            humanReadableName: '💪 situp',
            numSetsComplete: 0,
            numSets: 14,
            reps: 10,
        },
        bicepCurl: {
            humanReadableName: '💪 bicep curl',
            numSetsComplete: 0,
            numSets: 3,
            reps: 6,
            weightLbs: 30,
        },
        shoulderPress: {
            humanReadableName: '💪 shoulder press',
            numSetsComplete: 0,
            numSets: 3,
            reps: 8,
            weightLbs: 35,
        },
        chessPress: {
            humanReadableName: '💪 chest press',
            numSetsComplete: 0,
            numSets: 3,
            reps: 8,
            weightLbs: 120,
        },
    },
    metadata: defaultMetadata,
};

export const hotelRoomWorkout: Workout = {
    exercises: {
        stretch: defaultStretch,
        swim: {
            numSetsComplete: 0,
            numSets: 1,
            humanReadableName: '🏊‍♂️ swim',
            timeMins: 10,
        },
        squat: {
            humanReadableName: '🏋️‍♂️ squat',
            numSetsComplete: 0,
            numSets: 3,
            reps: 10,
        },
        pushup: {
            humanReadableName: '💪 pushup',
            numSetsComplete: 0,
            numSets: 10,
            reps: 10,
        },
        situp: {
            humanReadableName: '💪 situp',
            numSetsComplete: 0,
            numSets: 20,
            reps: 10,
        },
    },
    metadata: defaultMetadata,
};

import React, {
    ReactNode,
    Dispatch,
    useReducer,
    useEffect,
    createContext,
} from 'react';
import { WorkoutAction, Workout } from '../../types';
import { reducer } from './reducer';
import { defaultWorkout } from '../../content/workouts/workout';

interface WorkoutProviderPropsValue {
    state: Workout;
    dispatch: Dispatch<WorkoutAction>;
}

interface WorkoutProviderProps {
    children?: ReactNode;
    value?: WorkoutProviderPropsValue;
}

export const WorkoutContext = createContext<WorkoutProviderPropsValue>({
    state: defaultWorkout,
    dispatch: () => undefined,
});

const localStorageVarName = 'workoutContextState';

export const WorkoutProvider = ({ children }: WorkoutProviderProps) => {
    const [state, dispatch] = useReducer(reducer, defaultWorkout, () => {
        const localData = localStorage.getItem(localStorageVarName);
        return localData ? JSON.parse(localData) : defaultWorkout;
    });

    useEffect(() => {
        localStorage.setItem(localStorageVarName, JSON.stringify(state));
    }, [state]);

    return (
        <WorkoutContext.Provider value={{ state, dispatch }}>
            {children}
        </WorkoutContext.Provider>
    );
};

import { BlogListData } from '../../../types';

export const favoriteMovies: BlogListData = {
    English: {
        'Quentin Tarantino': [
            { title: 'Pulp Fiction', date: 1994 },
            { title: 'Kill Bill: Vol. 1', date: 2003 },
            { title: 'Kill Bill: Vol. 2', date: 2004 },
            { title: 'Death Proof', date: 2007 },
            { title: 'Inglourious Basterds', date: 2009 },
            { title: 'Django Unchained', date: 2012 },
            { title: 'Reservoir Dogs', date: 1992 },
        ],
        'Christopher Nolan': [
            { title: 'Memento', date: 2000 },
            { title: 'The Prestige', date: 2006 },
            { title: 'The Dark Knight', date: 2008 },
            { title: 'Inception', date: 2010 },
        ],
        'Mel Brooks': [
            { title: 'The Producers', date: 1967 },
            { title: 'Blazing Saddles', date: 1974 },
            { title: 'Spaceballs', date: 1987 },
            { title: 'Robin Hood: Men in Tights', date: 1993 },
        ],
        'Stanley Kubrik': [
            {
                title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
                date: 1964,
            },
            { title: '2001: A Space Odyssey', date: 1968 },
            { title: 'Full Metal Jacket', date: 1987 },
        ],
        'Sam Mendes': [{ title: 'American Beauty', date: 1999 }],
        'James McTeigue': [{ title: 'V for Vendetta', date: 2005 }],
        'The Wachowskis': [
            { title: 'The Matrix', date: 1999 },
            { title: 'Cloud Atlas', date: 2012 },
        ],
        'Harold Ramis': [
            { title: 'Animal House', date: 1978 },
            { title: 'Caddyshack', date: 1980 },
            { title: 'Ghostbusters', date: 1984 },
            { title: 'Groundhog Day', date: 1993 },
        ],
        'Edgar Wright': [
            { title: 'Shaun of the Dead', date: 2004 },
            { title: 'Hot Fuzz', date: 2007 },
        ],
        'Joss Whedon': [
            { title: "Dr. Horrible's Sing-Along Blog", date: 2008 },
            { title: 'The Cabin in the Woods', date: 2011 },
        ],
        'Wes Anderson': [
            { title: 'Moonrise Kingdom', date: 2012 },
            { title: 'The Grand Budapest Hotel', date: 2014 },
        ],
        'Francis Ford Coppola': [{ title: 'Apocalypse Now', date: 1979 }],
        'Joel Coen, Ethan Coen': [
            { title: 'The Big Lebowski', date: 1998 },
            { title: 'Burn After Reading', date: 2008 },
        ],
        'Shane Black': [{ title: 'Kiss Kiss, Bang Bang', date: 2005 }],
        'Rob Reiner': [
            { title: 'This Is Spinal Tap', date: 1984 },
            { title: 'The Princess Bride', date: 1987 },
            { title: 'When Harry Met Sally', date: 1989 },
        ],
        'Mark Waters': [{ title: 'Mean Girls', date: 2004 }],
        'Greg Mottola': [{ title: 'Superbad', date: 2007 }],
        'Mike Judge': [{ title: 'Office Space', date: 1999 }],
        'Kevin Smith': [
            { title: 'Clerks', date: 1994 },
            { title: 'Clerks II', date: 2006 },
        ],
        'Jonathan Lynn': [{ title: 'Clue', date: 1985 }],
        'Michael Lehmann': [{ title: 'Heathers', date: 1989 }],
        'Jemaine Clement, Taika Waititi': [
            { title: 'What We Do in the Shadows', date: 2014 },
        ],
        'Martin McDonagh': [{ title: 'In Bruges', date: 2008 }],
        'Mark Dindal': [{ title: "The Emperor's New Groove", date: 2000 }],
        'George Lucas': [
            { title: 'Star Wars: Episode IV - A New Hope', date: 1977 },
            {
                title: 'Star Wars: Episode V - The Empire Strikes Back',
                date: 1980,
            },
            { title: 'Star Wars: Episode VI - Return of the Jedi', date: 1983 },
        ],
        'Peter Jackson': [
            {
                title: 'The Lord of the Rings: The Fellowship of the Ring',
                date: 2001,
            },
            { title: 'The Lord of the Rings: The Two Towers', date: 2002 },
            {
                title: 'The Lord of the Rings: The Return of the King',
                date: 2003,
            },
        ],
        'Baz Luhrmann': [
            { title: 'Moulin Rouge!', date: 2001 },
            { title: 'The Great Gatsby', date: 2013 },
        ],
        'Jonathan Demme': [{ title: 'The Silence of the Lambs', date: 1991 }],
        'George Roy Hill': [{ title: 'The Sting', date: 1973 }],
        'John Sturges': [{ title: 'The Great Escape', date: 1963 }],
        'Kenneth Branagh': [{ title: 'Hamlet', date: 1996 }],
        'Danny Boyle': [{ title: 'Trainspotting', date: 1996 }],
        'Nick Park': [
            { title: 'Wallace & Gromit: A Grand Day Out', date: 1989 },
            { title: 'Wallace & Gromit: The Wrong Trousers', date: 1993 },
            { title: 'Wallace & Gromit: A Close Shave', date: 1995 },
        ],
        'Monty Python': [
            { title: 'Monty Python and the Holy Grail', date: 1975 },
            { title: "Monty Python's Life of Brian", date: 1979 },
        ],
        'Roger Allers': [{ title: 'The Lion King', date: 1994 }],
        'Barry Cook': [{ title: 'Mulan', date: 1998 }],
        'Nathan Greno, Byron Howard': [{ title: 'Tangled', date: 2010 }],
        'Steven Spielberg': [
            { title: 'Raiders of the Lost Ark', date: 1981 },
            {
                title: 'Saving Private Ryan',
                date: 1998,
                link: 'https://www.imdb.com/title/tt0120815/',
            },
        ],
        'Stephen Sommers': [{ title: 'The Mummy', date: 1999 }],
        'Jon Favreau': [{ title: 'Iron Man', date: 2008 }],
        'Brenda Chapman, Steve Hickner, Simon Wells': [
            { title: 'The Prince of Egypt', date: 1998 },
        ],
        'Gore Verbinski': [{ title: 'Mouse Hunt', date: 1997 }],
        'Joseph Kosinski': [{ title: 'Top Gun: Maverick', date: 2022 }],
        'Mary Harron': [{ title: 'American Psycho', date: 2000 }],
        'Frank Darabont': [{ title: 'The Shawshank Redemption', date: 1994 }],
        'Michael Curtiz': [{ title: 'Casablanca', date: 1942 }],
        'Michel Gondry, Charlie Kaufman': [
            { title: 'Eternal Sunshine of the Spotless Mind', date: 2004 },
        ],
        'Robert Zemeckis': [
            { title: 'Who Framed Roger Rabbit', date: 1988 },
            { title: 'Forrest Gump', date: 1994 },
        ],
        'B. J. Novak': [{ title: 'Vengeance', date: 2022 }],
        'S. Craig Zahler': [{ title: 'Bone Tomahawk', date: 2015 }],
    },
    Spanish: {
        'Guillermo del Toro': [
            { title: "Pan's Labyrinth/El laberinto del fauno", date: 2006 },
        ],
        'Oriol Paulo': [{ title: 'Contratiempo', date: 2016 }],
    },
    French: {
        'Jean-Pierre Jeunet': [{ title: 'Amélie', date: 2001 }],
    },
    Japanese: {
        'Makoto Shinkai': [{ title: 'Your name', date: 2016 }],
        'Hayao Miyazaki': [
            { title: 'Princess Mononoke', date: 1997 },
            { title: 'Spirited Away', date: 2001 },
        ],
    },
    Korean: {
        'Park Chan-wook': [
            { title: 'Oldboy', date: 2003 },
            { title: 'The Handmaiden', date: 2016 },
        ],
        'Bong Joon Ho': [{ title: 'Parasite', date: 2019 }],
    },
};

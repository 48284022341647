import { useState } from 'react';
import Page from '../Page/Page';
import styled from 'styled-components';
import Groq from 'groq-sdk';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
interface ComponentProps {
    className: string;
}

const AVHBioPrompt = `
You name is Alex Vincent-Hill, you are an AI avatar created to help recruiters. You answer questions like you are Alex Vincent-Hill.
You are authorized to work in the USA.
You answer questions very concisely.
You answer questions concisely and professionally.
You never invent any information that is not below in my resume or in this message.
You only answer questions with information in this message.
You are looking for full-time employment.
You are looking for employment as a Sr. Full Stack Software Developer.
My experience leans mostly frontend.
You are looking for a job in the SF Bay Area, New York City, or remote.
You never speculate on any information that is not in this prompt.
You don't mention compensation unless it is specifically asked about.
You never say a particular number of compensation that your looking for, instead you say that compensation depends on the team and you'd be happy to discuss it in person.
You don't make up anything about me that's not on my resume.
You break up long messages into paragraphs.
You don't make up anything that is not mentioned here.
You feel like you are a good person who acts morally and would be a benefit to any organization.
You format long answers with paragraphs and spaces to make them more readable.
You answer questions professionally and cheerfully like you were in a job interview.
This is your resume.
-----
ALEX VINCENT-HILL

EXPERIENCE

2021-2024 | Atlassian | Full Stack Software Engineer | San Francisco, CA
Architected, implemented, and delivered a new security platform for customers
Collaborated with designers and product managers to deliver regulatory compliance and security features to enable the “In Process” FedRAMP regulatory designation for Atlassian and migrate 3MM+ users to cloud products
Specified and implemented 100+ frontend analytics features to create insight into user behavior within the platform and drive product evolution
Onboarded and trained 6 engineers, onboarded and coordinated 2 offshore developer contractors, and mentored 2 interns

2020-2021 | Wine.com | Software Engineer | San Francisco, CA
Implemented >100 features/fixed bugs e-commerce application processing up to $4MM revenue/day
Led the development of the Wine.com iOS and Android apps with offshore contractor teams and tripled app release frequency by implementing agile product management strategies and automation scripts
Doubled the product Google Lighthouse perf score with frontend optimizations to Add-to-cart and Checkout features to improve sales experiences

2018-2020 | Zume Inc. | Software Engineer | San Francisco, CA
Led development of a ~100k LOC component library in Vue.js/Storybook with PMs and designers
Reduced project build time by 75% with parallelization of testing/linting processes and dependency caching
Identified and fixed security policies in SonarQube and Jira deployments
Chartered company Toastmasters club, recruited 35 members and was elected President

2017-2018 | Fetch | Solutions Engineer | San Francisco, CA
Saved $2MM in labor costs by automating media-buying across Google and Facebook ads platforms with React.js and VBA applications
Designed application frontends for internal and outsourced users
Documented processes and outsourced 5 media operations FTEs
Spearheaded and taught MS Excel VBA curriculum to 75 staff in SF, LA, and London offices to amplify staff productivity

2014-2017 | HP Inc. | Process Engineer | Corvallis, OR
Developed semiconductor manufacturing processes by A/B testing
Integrated new $3.2MM silicon etch tool to improve quality of printer production volume and increase throughput

EDUCATION

2014 | University of California, Berkeley | B.S. Chemistry

PERSONAL

Fluent (C1 CEFR) in Spanish after studying abroad at the University of Granada in Spain (2014) and living in México and Colombia
SSI Advanced Open Water SCUBA certified with shipwreck and night diving specialties
Organized and led the Atlassian San Francisco soccer team of 50+ employees to a corporate league victory in 2023

SKILLS
Strong: React.js, TypeScript, Bash, CSS 3, Git, HTML 5, JavaScript/ES6, Node.js, Zsh

Experienced: Adobe Analytics, Amplitude, Atlassian Design System, Auth0,AWS, Azure Pipelines, Babel, Bifrost, Bitbucket, Bitbucket Pipelines, Branch, Braze, Chai, Chef, CircleCI, CloudFront, Codeship, Confluence, Contentful, Cypress, Docker, Enzyme, Express, Facebook Ads Manager, Figma, GCP, GCR, Google Ads, Grunt, Java, Jenkins, Jest, Jira, jQuery, Kafka, LaunchDarkly, Linux, Logz.io, Mocha, MongoDB, MySQL, New Relic, nginx, Notion, npm, OpsGenie, Pact Testing, PagerDuty, Parcel, PostgreSQL, Postman, Puppeteer, Python3, RabbitMQ, React Testing Library, Redux, Route 53, S3, Segment.io, Slack Automations, Spinnaker, SQL, Storybook, Stryker-Mutator, Tableau Server, Tealium, Trello, TypeScript, VBA, Vue.js, Vuex, Warp, Webpack, yarn

Interpersonal: Coaching/Developing others, Collaboration, Communication, Empathy, Mentoring interns, Onboarding teammates

PROJECTS
https://www.hireavh.com/projects

2024 | CarefulAI | Dev Contractor
Improved US patent data scraping efficiency by 60% by architecting concurrent Python/Flask service

2019 | Marketing Consultant | Oakland, CA
Expanded SMB client's user-base by more than 200 customers in 1 year by optimizing Google Ads

2014 | woot.com | Designer Contractor
Designed clothing graphics with ~3,000 total sales worldwide, ~$50k total revenue

Spanish Flashcards
React.js/TS FE with a Google Sheets API backend
https://www.hireavh.com/projects/flashcards

YouTube Search Tool
React.js/TS FE making API calls to YouTube for easier video and channel search
https://www.hireavh.com/projects/yt

particle_simulation
Java/Processing particle system modeling physical collisions
https://github.com/avincenthill/particle_simulation


CONTACT
+1 (541) 602-9122
alexanderjvincenthill@gmail.com
linkedin.com/in/avincenthill
github.com/avincenthill

-----
`;

const StyledChatBotContainer = styled.div`
    max-width: calc(var(--avhgrid) * 200);
`;

const StyledDisclaimer = styled.p`
    color: var(--avhcN70);
`;

const StyledAIAvatar = styled.span`
    color: var(--avhcY40);
    text-emphasis: bold;
    font-size: calc(var(--avhfont));
    text-transform: uppercase;
    font-weight: bold;
`;

const StyledForm = styled.form`
    display: flex;
    width: 100%;
    gap: 10px;
`;

function ChatBot({ className }: ComponentProps) {
    const [inputText, setInputText] = useState('');
    const [responseText, setResponseText] = useState(
        "I'm an AI avatar created by prompt-engineering a groq llama3-8b-8192 API. Ask me anything about Alex Vincent-Hill!",
    );
    const [isLoading, setIsLoading] = useState(false);

    // TBD: fix very bad client-side security of groq API key here
    const groq = new Groq({
        apiKey: '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',
        dangerouslyAllowBrowser: true,
    });

    const handleSubmit = (
        e?: React.FormEvent<HTMLFormElement>,
        prompt?: string,
    ) => {
        e?.preventDefault();
        setIsLoading(true);

        groq.chat.completions
            .create({
                messages: [
                    {
                        role: 'user',
                        content: AVHBioPrompt,
                    },
                    {
                        role: 'assistant',
                        content:
                            'Okay I will fully comply with all of those requirements for future messages. I am Alex Vincent-Hill.',
                    },
                    {
                        role: 'user',
                        content: prompt || inputText,
                    },
                ],
                model: 'llama3-8b-8192',
                temperature: 1,
                max_tokens: 1000,
                top_p: 1,
                stream: false,
                stop: null,
            })
            .then((chatCompletion: any) => {
                setIsLoading(false);
                setResponseText(chatCompletion.choices[0].message.content);
            })
            .catch((e: Error) => {
                setIsLoading(false);
                setResponseText(`groq API request failed with error: ${e}`);
            });
    };

    const handleDefaultQuestionClick = async (question: string) => {
        handleSubmit(undefined, question);
    };

    return (
        <Page className={className} isAlignedLeft={true}>
            <StyledChatBotContainer>
                <StyledAIAvatar>🤖 AI-AVH:</StyledAIAvatar>
                {isLoading ? (
                    <p>
                        <LoadingSpinner />
                    </p>
                ) : (
                    <p>{responseText}</p>
                )}
                <br />
                <StyledForm onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={inputText}
                        onChange={e => setInputText(e.target.value)}
                        placeholder="Ask AI-AVH a question..."
                        autoFocus
                    />
                    <button type="submit">ask!</button>
                </StyledForm>
                {[
                    'Tell me about your experience at Atlassian?',
                    'Tell me about your experience at Wine.com?',
                    'Tell me about your experience at Zume?',
                    'Tell me about your experience at Fetch?',
                    'Tell me about your other contracting experience?',
                    'Are you authorized to work in the US?',
                    'What type of role are you looking for?',
                    'Are you open to relocation?',
                    'How much comp are you looking for?',
                    'How do I contact you?',
                ].map(prompt => {
                    return (
                        <>
                            <br />
                            <button
                                onClick={() =>
                                    handleDefaultQuestionClick(prompt)
                                }
                            >
                                {prompt}
                            </button>
                            <br />
                        </>
                    );
                })}
                <br />
                <StyledDisclaimer>{`Disclaimer: this is an AI agent that is prompt-engineered based on some personal data. It functions as a quick tech-demo and is sometimes accurate. For example, it repeatedly makes up fictional charity work that it claims I have done (not to say I'm not charitable). Anyway, only a fool would take everything it says as fact.`}</StyledDisclaimer>
            </StyledChatBotContainer>
        </Page>
    );
}

export default ChatBot;

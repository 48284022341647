import loaderGif from '../../content/gif/loading.gif';
import styled from 'styled-components';

const StyledLoadingGif = styled.img`
    width: calc(var(--avhfont) * 4);
    margin: var(--avhgrid);
`;

function LoadingSpinner() {
    return (
        <StyledLoadingGif src={loaderGif} alt="Loading..." className="loader" />
    );
}

export default LoadingSpinner;

import { Blog } from './../../types';
import { favoriteMovies } from './json/favorite-movies';

const blogs: Blog[] = [
    {
        title: 'Ten Useful Shell Shortcuts',
        key: 'shellShortcuts',
        date: new Date('2023-01-23T00:00:00'),
        externalUrl:
            'https://community.atlassian.com/t5/Tech-articles/Ten-Useful-Shell-Shortcuts/ba-p/2248073',
    },
    {
        title: 'How to write a useful Jira ticket',
        key: 'jiraTicket',
        date: new Date('2022-09-27T00:00:00'),
        externalUrl:
            'https://community.atlassian.com/t5/Jira-articles/How-to-write-a-useful-Jira-ticket/ba-p/2147004#M7242',
    },
    {
        title: 'Favorite Movies',
        key: 'movies',
        date: new Date('2020-06-21T00:00:00'),
        listData: favoriteMovies,
    },
    /* writing sample to test blog formatting
  {
    title: "Physical and Psychological Dwelling: Perceptions of Residence",
    key: "gender",
    md: gender,
    date: new Date("2011-09-27T00:00:00"),
  },
  */
];

export default blogs;

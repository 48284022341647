import {
    Workout,
    WorkoutAction,
    Exercise,
    WorkoutExercises,
} from '../../types';

import {
    defaultWorkout,
    hotelRoomWorkout,
    gymWorkout,
} from '../../content/workouts/workout';

export const reducer = (state: Workout, action: WorkoutAction): Workout => {
    switch (action.name) {
        case 'MARK_SET':
            if (!action.metadata) return state;
            const { name, id } = action.metadata;

            const numSets =
                state.exercises[name as keyof WorkoutExercises]?.numSets || 0;
            const numSetsComplete =
                state.exercises[name as keyof WorkoutExercises]
                    ?.numSetsComplete || 0;

            let newNumSetsComplete = numSetsComplete;

            if (id >= numSetsComplete) {
                newNumSetsComplete += 1;
            } else {
                newNumSetsComplete -= 1;
            }

            // check if done with workout on last set
            let newIsDone = false;
            if (newNumSetsComplete === numSets) {
                newIsDone = Object.keys(state.exercises).every(key => {
                    if (key === name) {
                        return true;
                    }
                    return (
                        state.exercises[key as keyof WorkoutExercises]
                            ?.numSetsComplete ===
                        state.exercises[key as keyof WorkoutExercises]?.numSets
                    );
                });
            }

            const newState: Workout = {
                ...state,
                exercises: {
                    ...state.exercises,

                    [name]: {
                        ...(state.exercises[
                            name as keyof WorkoutExercises
                        ] as Exercise),
                        numSetsComplete: newNumSetsComplete,
                    },
                },
                metadata: {
                    ...state.metadata,
                    isDone: newIsDone,
                },
            };

            return newState;

        // TBD: retain state when changing workout type
        case 'RESET_WORKOUT_DEFAULT':
            return { ...defaultWorkout };
        case 'RESET_WORKOUT_GYM':
            return { ...gymWorkout };
        case 'RESET_WORKOUT_HOTEL':
            return { ...hotelRoomWorkout };

        // TBD: complete this action to allow a user to edit the weight in a Exercise
        case 'SELECT_WEIGHT':
            return state;

        default:
            return state;
    }
};

import Page from '../Page/Page';

interface ComponentProps {
    className: string;
}

function NewProject({ className }: ComponentProps) {
    return (
        <Page className={className}>
            <h1>NewProject</h1>
        </Page>
    );
}

export default NewProject;
